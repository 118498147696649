<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm" v-if="!gameIsStarted && !gameData.hasJoined">
          <div class="alert alert-danger" v-if="!user.isLoggedIn">
            {{ 'Please log in'|translate }}
          </div>
          <h4>{{ 'Available games'|translate }}</h4>
          <ul class="list-group games mb-5" v-if="games.length > 0">
            <li class="list-group-item game col-md-12" v-for="game in games">
              <span class="col-md-2" v-if="gameData.game === null || gameData.game._id !== game._id || !gameData.playerID">
                <span class="btn-group">
                  <router-link :to="{ name: 'Game', params: { id: game._id }}" custom v-slot="{ navigate }">
                      <a class="btn btn-primary btn-sm" @click="navigate" @keypress.enter="navigate">
                        {{ 'Enter'|translate }}
                      </a>
                  </router-link>
                  <a class="btn btn-danger btn-sm" @click="removeGame(game._id)" :title="$t('Delete')"
                     v-if="user.role === 'admin' || (gameData.playerID && game.createdByID === gameData.playerID)"><i class="fa fa-trash"></i></a>
                </span>
              </span>
              <span class="col-md-2" v-if="gameData.game !== null && gameData.game._id === game._id && gameData.playerID">
                {{ 'You are in this game'|translate }}
              </span>
              <span class="col-md-4">
                {{ 'Game'|translate }} <span class="game-id badge">{{ game.name }}</span> {{ 'created by'|translate }} <span
                  class="game-creator">{{ game.createdBy }}</span>
              </span>
              <span class="col-md-3">
                {{ 'Player count'|translate }} <span class="badge badge-info players">{{ game.players }}</span>
              </span>
              <span class="col-md-3">
                {{ 'Status'|translate }} <span class="badge badge-info status">{{ game.status|translate }}</span>
                <span class="ml-1 badge badge-warning status" v-if="!game.isPublic"> {{ 'private'|translate }}</span>
              </span>
            </li>
          </ul>
          <div class="alert alert-info" v-else>
            {{ 'No games available at the moment. Create one!'|translate }}
          </div>

          <h4>{{ 'Create new game'|translate }}</h4>
          <form v-on:submit.prevent>
            <p class="input-group mt-3">
              <input type="text" class="form-control" :placeholder="$t('Enter name')" v-model="newGame">
              <span class="input-group-append">
                <button type="submit" class="btn btn-primary" @click="addGame()">{{ 'Create'|translate }}</button>
              </span>
            </p>
            <b-form-checkbox v-model="isPublic">{{ 'Public'|translate }}</b-form-checkbox>
          </form>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Player from './Player'
import AuthService from '../services/auth.service'

const auth = new AuthService()

export default {
  name: 'games',
  props: {
    msg: String
  },
  data() {
    return {
      games: [],
      ok: false,
      isPublic: true,
      newGame: '',
      gameIsStarted: false,
      gameData: {game: null, playerID: null}
    }
  },
  computed: mapGetters({
    user: 'user'
  }),
  sockets: {
    /* eslint-disable object-shorthand */
    gameSave: function (data) {
      console.debug('gameSave!', data)
      let found = false
      this.games.forEach((game, idx) => {
        if (game._id === data._id) {
          game.players = data.players
          game.status = data.status
          found = true
        }
      })
      if (found === false && (data.isPublic === true || data.createdByID === this.user._id)) {
        this.games.push(data)
      }
    },
    gameRemoved: function (data) {
      if (typeof data.games !== 'undefined') {
        this.games = data.games
      } else if (typeof data.id !== 'undefined') {
        this.games.forEach((game, idx) => {
          if (game._id === data.id) {
            this.games.splice(idx, 1)
          }
        })
      }
    }
  },
  mounted() {
    this.gameData.user = this.user
    this.gameData.playerID = this.user._id
    this.axios.get(`${this.$store.state.game.apiURL}/api/games`, {
      params: {
        userID: this.user._id
      }
    }).then((response) => {
      this.initialized = true
      this.games = response.data
      if (this.user.isLoggedIn) {
        this.gameData.playerID = this.user._id
        this.gameData.user = this.user
      } else {
        auth.getUserAsync().then(res => {
          if (res !== undefined && typeof res._id !== 'undefined') {
            this.gameData.playerID = res._id
            this.gameData.user = res
          }
        })
      }
    }).catch(err => {
      console.error('error getting games:', err)
    })
  },
  methods: {
    removeGame(gameID) {
      this.$socket.client.emit('gameRemove', {
        id: gameID,
        playerID: this.gameData.playerID,
        playerName: this.gameData.user.name
      });
    },
    addGame() {
      if (this.newGame === '') {
        return false
      }
      this.$socket.client.emit('gameNew', {
        name: this.newGame,
        playerID: this.user._id,
        playerName: this.user.name,
        isPublic: this.isPublic
      })
      this.getGameList()
      this.newGame = ''
    },
    getGameList() {
      return this.axios.get(`${this.$store.state.game.apiURL}/api/games`, {
        params: {
          userID: this.user._id
        }
      }).then(response => {
        this.games = response.data
      }).catch(err => {
        console.error('error getting game list:', err)
      });
    }
  },
  components: {
    Player
  }
}
</script>
