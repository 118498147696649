<template>
  <footer>
    <hr>
    <div class="container">
      <p><span v-if="user.isLoggedIn">{{ $t('Hello, {username}', {username: user.name}) }} | </span>Raitsch <span
          class="version">v0.1</span> @ {{ mode }} | Felix Moche</p>
      <div id="locale-wrap" class="btn-group mb-3">
        <b-button :class="{ active: this.locale === 'de'}" size="sm" variant="primary" @click="changeLanguage('de')">
          {{ 'BUTTON_TEXT_DE' | translate }}
        </b-button>
        <b-button :class="{ active: this.locale === 'en'}" size="sm" variant="success" @click="changeLanguage('en')">
          {{ 'BUTTON_TEXT_EN' | translate }}
        </b-button>
        <b-button :class="{ active: soundEnabled}" @click.prevent="toggleMute">
          <i class="fa" :class="{'fa-volume-up': soundEnabled, 'fa-volume-off': !soundEnabled}"></i>
        </b-button>
      </div>
      <br>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from 'vuex'
import store from '../store'

export default {
  name: 'pageFooter',
  data() {
    return {
      locale: this.$i18n.locale(),
      mode: process.env.NODE_ENV || 'unknown'
    }
  },
  computed: mapGetters({
    soundEnabled: 'soundEnabled',
    user: 'user',
    step: 'step'
  }),
  methods: {
    soundTest(id) {
      this.$playSound(id)
    },
    toggleMute() {
      store.commit({type: 'TOGGLE_MUTE'})
    },
    changeLanguage(locale) {
      if (this.$i18n.localeExists(locale)) {
        this.locale = locale
        this.$i18n.set(this.locale)
        this.$store.commit('SET_LANGUAGE', {language: locale})
      }
    }
  }
}
</script>

<style lang="scss">
.footer {
  text-align: center;
  padding: 30px 0;
  margin-top: 70px;
  border-top: 1px solid #E5E5E5;

  p {
    font-size: .9em
  }
}

.version {
  word-break: break-all
}

#locale-wrap {
  margin: 7px 0 0;

  button {
    outline: none;

    &.active {
      font-weight: bold;
    }
  }
}
</style>
