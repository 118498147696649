<template>
  <div id="header">
    <div class="fixed-top">
      <div class="nav-wrapper">
        <div class="container">
          <b-navbar toggleable="md">
            <b-nav-toggle target="nav_collapse"></b-nav-toggle>

            <div class="flex-row">
              <b-navbar-brand id="raitsch">
                <router-link to="/" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <span class="nav-bar-nav"><a class="inner-nav-link" style="color:black" :href="href" @click="navigate">Raitsch</a></span>
                </router-link>
              </b-navbar-brand>
            </div>

            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav class="ml-auto">
                <router-link to="/about" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="nav-item" :class="{'router-link-active':isActive}">
                    <a class="nav-link" :href="href" @click="navigate">{{ 'About' | translate }}</a>
                  </li>
                </router-link>
                <router-link to="/games" custom v-slot="{ href, route, navigate, isActive, isExactActive }" v-if="user.isLoggedIn">
                  <li class="nav-item" :class="{'router-link-active':isActive}">
                    <a class="nav-link" :href="href" @click="navigate">{{ 'Games' | translate }}</a>
                  </li>
                </router-link>
                <router-link to="/signup" custom v-slot="{ href, route, navigate, isActive, isExactActive }" v-if="!user.isLoggedIn">
                  <li class="nav-item" :class="{'router-link-active':isActive}">
                    <a class="nav-link" :href="href" @click="navigate">{{ 'Signup' | translate }}</a>
                  </li>
                </router-link>
                <router-link to="/login" custom v-slot="{ href, route, navigate, isActive, isExactActive }" v-if="!user.isLoggedIn">
                  <li class="nav-item" :class="{'router-link-active':isActive}">
                    <a class="nav-link" :href="href" @click="navigate">{{ 'Login' | translate }}</a>
                  </li>
                </router-link>
                <li class="nav-item" v-if="user.isLoggedIn">
                  <a class="nav-link" href="" @click="logout()">{{ 'Logout' | translate }}</a>
                </li>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>
    </div>
    <!--        <messages></messages>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '../store';
import AuthService from '../services/auth.service';

export default {
  name: 'pageHeader',
  data() {
    return {};
  },
  computed: mapGetters({
    user: 'user'
  }),
  // components: {
  //     Messages
  // },
  methods: {
    logout() {
      const auth = new AuthService();
      auth.logout()
          .then(() => {
            store.commit('USER_LOGOUT');
            this.$router.push({path: '/'});
          })
          .catch(() => {
            store.commit('USER_LOGOUT');
            this.$router.push({name: 'Login'});
          });
    }
  }
};
</script>

<style>
#header {
  height: 72px;
}

.navbar {
  background-color: transparent;
  border-color: #ddd;
  padding-left: 0;
  padding-right: 0;
}

.container {
  padding: 0;
}

.router-link-active a {
  color: rgba(0, 0, 0, 0.7);
}

.nav-item {
  position: relative;
}
</style>
