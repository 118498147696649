<template>
  <div>
    <div class="container login">
      <form action="/login" method="post" id="login-form" v-on:submit.prevent>
        <div class="form-group">
          <b-form-input v-model="login.email"
                        autocomplete="email"
                        type="text"
                        :placeholder="$t('Email')"
          ></b-form-input>
        </div>
        <div class="form-group">
          <b-form-input v-model="login.password"
                        autocomplete="current-password"
                        type="password"
                        :placeholder="$t('Password')"
          ></b-form-input>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" type="submit" @click="handleLogin">{{ 'Login' | translate }}</button>
        </div>
      </form>
      <div class="alert alert-info" v-if="error !== false">{{ error | translate }}</div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/auth.service'

export default {
  name: 'login',
  data() {
    let error = false
    return {
      login: {},
      error
    }
  },
  methods: {
    handleLogin() {
      const auth = new AuthService()
      auth.login(this.login)
          .then(() => {
            this.$router.push({path: '/'})
          })
          .catch(err => {
            if (err.response) {
              this.error = err.response.data.message
              return err.response
            }
          })
    },
    failed(errors) {
      console.error('failed: ', errors)
    },
    logout() {
      this.$cookie.delete('token')
    }
  }
}
</script>
