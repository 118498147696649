import * as types from '../mutation-types'
import User from '../../classes/user'
import store from '../index'

/* eslint-disable no-process-env */
/* eslint-disable indent */
const stateData = {
          version:             0,
          points:              0,
          statsID:             null,
          language:            'de',
          soundEnabled:        false,
          user:                new User(),
          isInitialized:       false,
          apiURL:              process.env.NODE_ENV !== 'production'
              ? `http://${process.env.HOST || 'localhost'}:4000`
              : 'https://raitsch.moches.de'
      },
      getters   = {
          user:           state => state.user,
          initialization: state => state.isInitialized,
          soundEnabled:   state => state.soundEnabled,
          points:         state => state.points
      },
      actions   = {
      },
      mutations = {
          [types.SET_VERSION](state, { version }) {
              state.version = version
          },
          [types.SAVE_GAME](state) {
          },
          [types.SET_KEY](state, { key }) {
              state.key = key
          },
          [types.SET_LANGUAGE](state, { language }) {
              state.language = language
          },
          [types.SET_POINTS](state, { points }) {
              state.points = points
          },
          [types.USER_LOGIN](state, { user }) {
              state.user = user
          },
          [types.SET_STATS_ID](state, { id }) {
              state.statsID = id
          },
          [types.USER_LOGOUT](state) {
              state.user = new User()
          },
          [types.INITIALIZE](state, status) {
              state.isInitialized = status
          },
          [types.UNMUTE](state) {
              state.soundEnabled = true
          },
          [types.MUTE](state) {
              state.soundEnabled = false
          },
          [types.TOGGLE_MUTE](state) {
              state.soundEnabled = !state.soundEnabled
          },
          [types.INIT_RESET](state) {
              state.doReset = true
          }
      }

export default {
    state: stateData,
    getters,
    actions,
    mutations
}
