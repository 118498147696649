/*eslint-disable */
export default {
    'Welcome': 'Willkommen bei Raitsch!',
    'No games available at the moment. Create one!': 'Keine Spiele vergübar. Erstell doch eins!',
    'Delete': 'Löschen',
    'Leave': 'Verlassen',
    'Start': 'Start',
    'Ready': 'Bereit',
    'Scoreboard': 'Scoreboard',
    'Cards/Players': 'Karten/Spieler',
    'Your turn!': 'Du bist dran!',
    'Make your prediction': 'Triff deine Voraussage',
    'Yellow': 'Gelb',
    'Red': 'Rot',
    'Blue': 'Blau',
    'Violet': 'Violett',
    'Green': 'Grün',
    'Orange': 'Orange',
    'Player count': 'Spieler',
    'Submit': 'Absenden',
    'Invalid!': 'Nicht zulässig!',
    'What value should your Joker have?': 'Welchen Wert soll der Joker haben?',
    'Prediction': 'Ansage',
    'Ticks': 'Stiche',
    'Game': 'Spiel',
    'Games': 'Spiele',
    'Signup': 'Registrieren',
    'Login': 'Login',
    'Logout': 'Logout',
    'Status': 'Status',
    'open': 'offen',
    'running': 'läuft',
    'finished': 'abgeschlossen',
    'Enter': 'Betreten',
    'created by': 'erstellt von',
    'You are in this game': 'Du bist im Spiel',
    'Please log in': 'Bitte einloggen!',
    'Available games': 'Verfügbare Spiele',
    'Create new game': 'Neues Spiel erstellen',
    'Create': 'Erstellen',
    'Enter name': 'Name eingeben',
    'BUTTON_TEXT_EN': 'EN',
    'BUTTON_TEXT_DE': 'DE',
    'Email': 'Email',
    'Name': 'Name',
    'Password': 'Passwort',
    'Round finished.': 'Runde beendet.',
    'Game finished.': 'Spiel beendet.',
    'New round starts.': 'Neue Runde beginnt.',
    'The winner is {player}': 'Gewonnen hat {player}',
    'Game starting - have fun!': 'Spiel startet - viel Spaß!',
    'Prediction by {player}: {prediction}': 'Voraussage von {player}: {prediction}',
    'Player {player} has entered the game.': 'Spieler {player} hat das Spiel betreten.',
    'Player {player} has left the game.': 'Spieler {player} hat das Spiel verlassen.',
    '{place}: {player} with {points} points': '{place}. Platz: {player} mit {points} Punkten',
    'Hello, {username}': 'Hallo, {username}',
    'Public': 'Öffentlich',
    'private': 'privat',
    'About': 'About',
    'Game is full': 'Spiel ist voll',
    'Not connected...': 'Nicht verbunden...',
    'MSG_INCORRECT_PASSWORD': 'Falsches Passwort',
    'MSG_EMAIL_NOT_REGISTERED': 'Kein Nutzer mit dieser Mailadresse gefunden',
    'CONNECTION_LOST': 'Das Spiel kann so leider nicht weitergehen... Warte ein bisschen.',
    'CONNECTION_LOST_HEADLINE': 'Verbindung zum Server verloren!',
    'CONNECTION_FOUND': 'Es kann jetzt weitergehen.',
    'CONNECTION_FOUND_HEADLINE': 'Verbindung wiederhergestellt!',
    'WAITING_FOR_SERVER': 'Warte auf Serververbindung...'
}
