<template>
  <div class="player" v-if="p.length > idx" :id="'player-' + idx" :class="{'active':p[idx].id === turn}">
    <p class="player-name">{{ p[idx].name }}</p>
    <div class="playingCards" v-if="p[idx].hiddenCard !== null">
      <div class="card">
        <div class="card-value value value-top">{{ p[idx].hiddenCard.value }}</div>
        <div class="card-color" :class="p[idx].hiddenCard.color">
          <div class="value value-middle">{{ p[idx].hiddenCard.value }}</div>
        </div>
      </div>
    </div>
    <p class="player-meta">
      {{ 'Prediction'|translate }}: <span class="prediction badge">{{ p[idx].prediction }}</span>
    </p>
    <p class="player-meta">
      {{ 'Ticks'|translate }}: <span class="badge tricks">{{ p[idx].tricks }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'player',
  data() {
    return {
      locale: this.$i18n.locale()
    }
  },
  props: {
    turn: String,
    idx: Number,
    p: Array
  }
}
</script>

<style lang="scss" scoped>
#table .player {
  height: 7em;
  width: 7em;
  display: inline-block;
  border: 2px solid #eee;
  background: #f5e1e2;
  border-radius: 4px;
  text-align: left;
  padding: .5em;
  box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.4);

  &.active {
    animation: pulse 2.5s ease-out infinite;
    background: #efefef;
  }
}

.player {
  p {
    margin-bottom: 0.5em;
  }
  .player-name {
    font-weight: bold;
    line-height: 1em;
  }
  .player-meta {
    font-size: .9em;
  }
}
</style>
