<template>
    <div>
        <div class="container hello">
            <form action="/login" method="post" id="signup-form" v-on:submit.prevent>
                <div class="form-group">
                    <b-form-input v-model="signup.name"
                                  autocomplete="name"
                                  type="text"
                                  :placeholder="$t('Name')"
                    ></b-form-input>
                </div>
                <div class="form-group">
                    <b-form-input v-model="signup.email"
                                  autocomplete="email"
                                  type="text"
                                  :placeholder="$t('Email')"
                    ></b-form-input>
                </div>
                <div class="form-group">
                    <b-form-input v-model="signup.password"
                                  autocomplete="current-password"
                                  type="password"
                                  :placeholder="$t('Password')"
                    ></b-form-input>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" type="submit" @click="handleRegistration">{{ 'Signup' | translate }}</button>
                </div>
            </form>
            <div class="alert alert-danger" v-if="error !== false">{{ error | translate }}</div>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/auth.service'

export default {
    name:    'signup',
    data() {
        let error = false
        return {
            signup: {
            },
            error
        }
    },
    methods: {
        handleRegistration() {
            const auth = new AuthService()
            auth.signup(this.signup)
                .then(() => {
                    this.$router.push({ path: '/' })
                })
                .catch(err => {
                    if (err.response) {
                        console.error('registration error:', err.response)
                        if (err.response.data.errors.email && err.response.data.errors.email.message) {
                            this.error = err.response.data.errors.email.message
                        } else if (err.response.data.errors.name && err.response.data.errors.name.message) {
                            this.error = err.response.data.errors.name.message
                        } else if (err.response.data.errors.password && err.response.data.errors.password.message) {
                            this.error = err.response.data.errors.password.message
                        } else {
                            this.error = err.response.data.message
                        }
                        return err.response
                    }
                })
        },
        failed(errors) {
            console.error('failed: ', errors)
        },
        logout() {
            this.$cookie.delete('token')
        }
    },
    components: {
    }
}
</script>
