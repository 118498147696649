<template>
  <li class="hand-card" :class="{'disabled': !canplay(data) && !forceopen, 'marked': marked}">
    <a href="#" class="card-link" @click.prevent="playcard(data)">
      <div class="card card-open" v-if="data.open || forceopen === true">
        <div class="value value-top">{{ data.value }}</div>
        <div class="card-color" :class="data.color">
          <div class="value value-middle">{{ data.value }}</div>
        </div>
      </div>
      <div class="card car-hidden" v-if="!data.open && !forceopen">
        <span class="hidden-card"></span>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'card',
  data() {
    return {
    }
  },
  props: ['data', 'canplay', 'playcard', 'player', 'forceopen', 'marked']
}
</script>
<style lang="scss">

.playingCards .card {
  display: inline-block;
  width: 8em;
  height: 11em;
  border: 1px solid #666;
  border-radius: .3em;
  -moz-border-radius: .3em;
  -webkit-border-radius: .3em;
  -khtml-border-radius: .3em;
  padding: .25em;
  margin: 0 .5em .5em 0;
  text-align: center;
  font-size: 1.2em;
  /* @change: adjust this value to make bigger or smaller cards */
  font-weight: normal;
  font-family: Arial, sans-serif;
  position: relative;
  background-color: #fff;
  -moz-box-shadow: .2em .2em .5em #333;
  -webkit-box-shadow: .2em .2em .5em #333;
  box-shadow: .2em .2em .5em #333;
}
.playingCards .mini-cards .card {
  width: 4em;
  height: 5.5em;
  font-size: 1em;
}
.playingCards ul.stack.mini-cards, .playingCards ul.unstacked.mini-cards {
  height: 6.5em;
  margin: 0;
}
.cards.stack li {
  left: 0;
  position: absolute;
}

.playingCards ul.hand li,
.playingCards ul.deck li {
  position: absolute;
}

.playingCards ul.hand li {
  bottom: 0;
}

.playingCards ul.unstacked {
  li:nth-child(1) {
    left: 0;
  }

  li:nth-child(2) {
    left: 1.5em;
  }

  li:nth-child(3) {
    left: 3em;
  }

  li:nth-child(4) {
    left: 4.5em;
  }

  li:nth-child(5) {
    left: 6em;
  }

  li:nth-child(6) {
    left: 7.5em;
  }

  li:nth-child(7) {
    left: 9em;
  }

  li:nth-child(8) {
    left: 10.5em;
  }

  li:nth-child(9) {
    left: 12em;
  }

  li:nth-child(10) {
    left: 13.5em;
  }

  li:nth-child(11) {
    left: 15em;
  }

  li:nth-child(12) {
    left: 16.5em;
  }

  li:nth-child(13) {
    left: 18em;
  }

  li:nth-child(14) {
    left: 19.5em;
  }

  li:nth-child(15) {
    left: 21em;
  }

  li:nth-child(16) {
    left: 22.5em;
  }
}

.mini-cards .card-color {
  padding: 5px;
}
.playingCards .mini-cards .card .value.value-middle {
  font-size: 1.5em;
  padding: 0;
}

.playingCards a.card {
  text-decoration: none;
}
/* selected and hover state */
.playingCards a.card:hover,
.playingCards a.card:active,
.playingCards a.card:focus,
.playingCards label.card:hover,
.playingCards strong .card {
  bottom: 2em;
}

.playingCards label.card {
  cursor: pointer;
}

.playingCards .card.back {
  text-indent: -4000px;
  background-color: #ccc;
}

.playingCards ul.table,
.playingCards ul.hand,
.playingCards ul.stack,
.playingCards ul.unstacked,
.playingCards ul.deck {
  list-style-type: none;
  padding: 0;
  margin: 0 0 3em 0;
  position: relative;
  clear: both;
}

.playingCards ul.hand {
  height: 12em;
}

.playingCards ul.table li,
.playingCards ul.hand li,
.playingCards ul.unstacked li,
.playingCards ul.stack li,
.playingCards ul.deck li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: left;
}

.playingCards ul.hand li,
.playingCards ul.unstacked li,
.playingCards ul.stack li,
.playingCards ul.deck li {
  position: absolute;
}

.playingCards ul.hand {
  li {
    bottom: 0;
  }
  li:nth-child(1) {
    left: 0;
  }
  li:nth-child(2) {
    left: 2.5em;
  }
  li:nth-child(3) {
    left: 5em;
  }
    li:nth-child(4) {
    left: 7.5em;
  }
  li:nth-child(5) {
    left: 10em;
  }
  li:nth-child(6) {
    left: 12.5em;
  }
  li:nth-child(7) {
    left: 15em;
  }
  li:nth-child(8) {
    left: 17.5em;
  }
  li:nth-child(9) {
    left: 20em;
  }
  li:nth-child(10) {
    left: 22.5em;
  }
  li:nth-child(11) {
    left: 25em;
  }
  li:nth-child(12) {
    left: 27.5em;
  }
  li:nth-child(13) {
    left: 30em;
  }
  li:nth-child(14) {
    left: 32.5em;
  }
  li:nth-child(15) {
    left: 35em;
  }
  li:nth-child(16) {
    left: 37.5em;
  }
}
/* rotate cards if rotateHand option is on */
.playingCards.rotateHand ul.hand {
  li:nth-child(1) {
    -moz-transform: translate(1.9em, 0.9em) rotate(-42deg);
    -webkit-transform: translate(1.9em, 0.9em) rotate(-42deg);
    -o-transform: translate(1.9em, 0.9em) rotate(-42deg);
    transform: translate(1.9em, 0.9em) rotate(-42deg);
  }

  li:nth-child(2) {
    -moz-transform: translate(1.5em, 0.5em) rotate(-33deg);
    -webkit-transform: translate(1.5em, 0.5em) rotate(-33deg);
    -o-transform: translate(1.5em, 0.5em) rotate(-33deg);
    transform: translate(1.5em, 0.5em) rotate(-33deg);
  }

  li:nth-child(3) {
    -moz-transform: translate(1.1em, 0.3em) rotate(-24deg);
    -webkit-transform: translate(1.1em, 0.3em) rotate(-24deg);
    -o-transform: translate(1.1em, 0.3em) rotate(-24deg);
    transform: translate(1.1em, 0.3em) rotate(-24deg);
  }

  li:nth-child(4) {
    -moz-transform: translate(0.7em, 0.2em) rotate(-15deg);
    -webkit-transform: translate(0.7em, 0.2em) rotate(-15deg);
    -o-transform: translate(0.7em, 0.2em) rotate(-15deg);
    transform: translate(0.7em, 0.2em) rotate(-15deg);
  }

  li:nth-child(5) {
    -moz-transform: translate(0.3em, 0.1em) rotate(-6deg);
    -webkit-transform: translate(0.3em, 0.1em) rotate(-6deg);
    -o-transform: translate(0.3em, 0.1em) rotate(-6deg);
    transform: translate(0.3em, 0.1em) rotate(-6deg);
  }

  li:nth-child(6) {
    -moz-transform: translate(-0.1em, 0.1em) rotate(3deg);
    -webkit-transform: translate(-0.1em, 0.1em) rotate(3deg);
    -o-transform: translate(-0.1em, 0.1em) rotate(3deg);
    transform: translate(-0.1em, 0.1em) rotate(3deg);
  }

  li:nth-child(7) {
    -moz-transform: translate(-0.5em, 0.2em) rotate(12deg);
    -webkit-transform: translate(-0.5em, 0.2em) rotate(12deg);
    -o-transform: translate(-0.5em, 0.2em) rotate(12deg);
    transform: translate(-0.5em, 0.2em) rotate(12deg);
  }

  li:nth-child(8) {
    -moz-transform: translate(-0.9em, 0.3em) rotate(21deg);
    -webkit-transform: translate(-0.9em, 0.3em) rotate(21deg);
    -o-transform: translate(-0.9em, 0.3em) rotate(21deg);
    transform: translate(-0.9em, 0.3em) rotate(21deg);
  }

  li:nth-child(9) {
    -moz-transform: translate(-1.3em, 0.6em) rotate(30deg);
    -webkit-transform: translate(-1.3em, 0.6em) rotate(30deg);
    -o-transform: translate(-1.3em, 0.6em) rotate(30deg);
    transform: translate(-1.3em, 0.6em) rotate(30deg);
  }

  li:nth-child(10) {
    -moz-transform: translate(-1.7em, 1em) rotate(39deg);
    -webkit-transform: translate(-1.7em, 1em) rotate(39deg);
    -o-transform: translate(-1.7em, 1em) rotate(39deg);
    transform: translate(-1.7em, 1em) rotate(39deg);
  }

  li:nth-child(11) {
    -moz-transform: translate(-2.2em, 1.5em) rotate(48deg);
    -webkit-transform: translate(-2.2em, 1.5em) rotate(48deg);
    -o-transform: translate(-2.2em, 1.5em) rotate(48deg);
    transform: translate(-2.2em, 1.5em) rotate(48deg);
  }

  li:nth-child(12) {
    -moz-transform: translate(-2.8em, 2.1em) rotate(57deg);
    -webkit-transform: translate(-2.8em, 2.1em) rotate(57deg);
    -o-transform: translate(-2.8em, 2.1em) rotate(57deg);
    transform: translate(-2.8em, 2.1em) rotate(57deg);
  }

  li:nth-child(13) {
    -moz-transform: translate(-3.5em, 2.8em) rotate(66deg);
    -webkit-transform: translate(-3.5em, 2.8em) rotate(66deg);
    -o-transform: translate(-3.5em, 2.8em) rotate(66deg);
    transform: translate(-3.5em, 2.8em) rotate(66deg);
  }
}
/* deck */
.playingCards ul.deck {
  li:nth-child(1) {
    left: 0;
    bottom: 0;
  }

  li:nth-child(2) {
    left: 2px;
    bottom: 1px;
  }

  li:nth-child(3) {
    left: 4px;
    bottom: 2px;
  }

  li:nth-child(4) {
    left: 6px;
    bottom: 3px;
  }

  li:nth-child(5) {
    left: 8px;
    bottom: 4px;
  }

  li:nth-child(6) {
    left: 10px;
    bottom: 5px;
  }

  li:nth-child(7) {
    left: 12px;
    bottom: 6px;
  }

  li:nth-child(8) {
    left: 14px;
    bottom: 7px;
  }

  li:nth-child(9) {
    left: 16px;
    bottom: 8px;
  }

  li:nth-child(10) {
    left: 18px;
    bottom: 9px;
  }

  li:nth-child(11) {
    left: 20px;
    bottom: 10px;
  }

  li:nth-child(12) {
    left: 22px;
    bottom: 11px;
  }

  li:nth-child(13) {
    left: 24px;
    bottom: 12px;
  }

  li:nth-child(14) {
    left: 26px;
    bottom: 13px;
  }

  li:nth-child(15) {
    left: 28px;
    bottom: 14px;
  }

  li:nth-child(16) {
    left: 30px;
    bottom: 15px;
  }

  li:nth-child(17) {
    left: 32px;
    bottom: 16px;
  }

  li:nth-child(18) {
    left: 34px;
    bottom: 17px;
  }

  li:nth-child(19) {
    left: 36px;
    bottom: 18px;
  }

  li:nth-child(20) {
    left: 38px;
    bottom: 19px;
  }

  li:nth-child(21) {
    left: 40px;
    bottom: 20px;
  }

  li:nth-child(22) {
    left: 42px;
    bottom: 21px;
  }

  li:nth-child(23) {
    left: 44px;
    bottom: 22px;
  }

  li:nth-child(24) {
    left: 46px;
    bottom: 23px;
  }

  li:nth-child(25) {
    left: 48px;
    bottom: 24px;
  }

  li:nth-child(26) {
    left: 50px;
    bottom: 25px;
  }

  li:nth-child(27) {
    left: 52px;
    bottom: 26px;
  }

  li:nth-child(28) {
    left: 54px;
    bottom: 27px;
  }

  li:nth-child(29) {
    left: 56px;
    bottom: 28px;
  }

  li:nth-child(30) {
    left: 58px;
    bottom: 29px;
  }

  li:nth-child(31) {
    left: 60px;
    bottom: 30px;
  }

  li:nth-child(32) {
    left: 62px;
    bottom: 31px;
  }
}

.cards.hand .hand-card:hover .card, .cards.hand .hand-card.marked .card {
  bottom: 30px;
  z-index: 999;
}

.hand-card.disabled,
.hand-card.disabled a {
  cursor: not-allowed;
}

.card {
  position: relative;
  .value.value-top {
    color: #000;
    position: absolute;
    left: 10px;
    top: 5px;
  }
  .value.value-middle {
    font-size: 4em;
    font-weight: bold;
    margin-top: 45%;
    -webkit-text-stroke: 1px white;
    color: black;
    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
  .card-color {
    height: 100%;
    padding: 5% 25px;
    background-color: #D0CBCB;
    &.yellow {
      background: #faff0a;
      background: linear-gradient(to right, #faff0a 0%, #ffffff 100%);
    }

    &.red {
      background: #c61407;
      background: linear-gradient(to right, #c61407 0%, #ffffff 100%);
    }

    &.green {
      background: #137204;
      background: linear-gradient(to right, #137204 0%, #ffffff 100%);
    }

    &.orange {
      background: #ed8609;
      background: linear-gradient(to right, #ed8609 0%, #ffffff 100%);
    }

    &.violet {
      background: #9B21B9;
      background: linear-gradient(to right, #9B21B9 0%, #ffffff 100%);
    }

    &.blue {
      background: #299FFF;
      background: linear-gradient(to right, #299FFF 0%, #ffffff 100%);
    }
  }
}
</style>
