import { EventBus } from '../api/eventbus'
import store from '../store'

export default class Sounds {
    constructor() {
        this.enabled = false
        this.sounds = [
            {
                id:     's1',
                url:    '/sfx/mixkit-arcade-game-complete-or-approved-mission-205.mp3'
            },
            {
                id:     's2',
                url:    '/sfx/mixkit-game-level-completed-2059.mp3'
            },
            {
                id:     's3',
                url:    '/sfx/mixkit-thin-metal-card-deck-shuffle-3175.mp3'
            },
            {
                id:     's4',
                url:    '/sfx/mixkit-unlock-game-notification-253.mp3'
            },
            {
                id:     's5',
                url:    '/sfx/mixkit-video-game-retro-click-237.mp3'
            },
            {
                id:     's6',
                url:    '/sfx/mixkit-message-pop-alert-2354.mp3'
            },
            {
                id:     's7',
                url:    '/sfx/mixkit-clear-announce-tones-2861.mp3'
            },
            {
                id:     's8',
                url:    '/sfx/19244__deathpie__carddrop2.mp3'
            },
            {
                id:     's9',
                url:    '/sfx/19245__deathpie__shuffle.mp3'
            },
            {
                id:     's10',
                url:    '/sfx/84322__splashdust__flipcard.mp3'
            },
            {
                id:     's11',
                url:    '/sfx/240777__f4ngy__dealing-card.mp3'
            }
        ]
        this.registerEvents()
    }

    registerEvents() {
        EventBus.$on('playSound', id => {
            this.play(id)
        })
        EventBus.$on('muteSound', () => {
            this.disable()
        })
        EventBus.$on('unMuteSound', () => {
            this.enable()
        })
    }

    play(id) {
        if (store.state.game.soundEnabled === true) {
            let file = this.sounds.filter(sound => sound.id === id)
            if (typeof file !== 'undefined') {
                const audio = new Audio(file[0].url)
                audio.play()
                return true
            }
        }
        return false
    }

    enable() {
        store.commit({ type: 'UNMUTE' })
        this.enabled = true
        return this.enabled
    }

    disable() {
        store.commit({ type: 'MUTE' })
        this.enabled = false
        return this.enabled
    }

    getSounds() {
        return this.sounds
    }

    isEnabled() {
        return this.enabled
    }

    toggleEnabled() {
        store.commit({ type: 'TOGGLE_MUTE' })
        this.enabled = !this.enabled
        return this.enabled
    }
}
