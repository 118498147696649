import Vue from 'vue'
import App from './App'
import router from './router'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'
import translationsEn from './i18n/en'
import translationsDe from './i18n/de'
import BootstrapVue from 'bootstrap-vue'
import store from './store'
import sockets from './api/sockets'
import VueLocalStorage from 'vue-localstorage'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import helper from './services/helper'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAxios from 'vue-axios'
import VueChatScroll from 'vue-chat-scroll'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import 'font-awesome/css/font-awesome.css'

Vue.config.productionTip = false
/* eslint-disable no-process-env */
const apiURL = process.env.NODE_ENV !== 'production'
    ? `http://${process.env.HOST || 'localhost'}:4000`
    : 'https://raitsch.moches.de'
const socket = io(apiURL)

Vue.use(VueSocketIOExt, socket)

Vue.use(VueCookie)
Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(vuexI18n.plugin, store)
Vue.use(VueLocalStorage)
Vue.use(VueChatScroll)

axios.interceptors.request.use(config => {
      const token = Vue.cookie.get('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }, error => Promise.reject(error))

Vue.use(VueAxios, axios)

Vue.i18n.add('en', translationsEn)
Vue.i18n.add('de', translationsDe)
if (store.state.hasOwnProperty('game')) {
    Vue.i18n.set(store.state.game.language)
} else {
    Vue.i18n.set('de')
}
Vue.use({ install: helper })

/* eslint-disable no-new */
new Vue({
  el:         '#app',
  router,
  store,
  sockets,
  render:     h => h(App),
  created:    () => {}
})
