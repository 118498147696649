export const RECEIVE_SHOPS    = 'RECEIVE_SHOPS',
    CALCULATE_EARNINGS = 'CALCULATE_EARNINGS',
    CALCULATE_INCOME_WHILE_GONE = 'CALCULATE_INCOME_WHILE_GONE',
    INCREASE_BALANCE = 'INCREASE_BALANCE',
    INIT_RESET = 'INIT_RESET',
    INITIALIZE = 'INITIALIZE',
    DECREASE_BALANCE = 'DECREASE_BALANCE',
    SET_BALANCE = 'SET_BALANCE',
    SET_STATS_ID = 'SET_STATS_ID',
    SET_LIFETIME_EARNINGS = 'SET_LIFETIME_EARNINGS',
    SET_LANGUAGE = 'SET_LANGUAGE',
    USER_LOGIN = 'USER_LOGIN',
    USER_LOGOUT = 'USER_LOGOUT',
    MODIFY_SHOP_ATTRIBUTE = 'MODIFY_SHOP_ATTRIBUTE',
    SHOW_TOTAL_INCOME_PER_SECOND = 'SHOW_TOTAL_INCOME_PER_SECOND',
    BUILD_BRANCHES = 'BUILD_BRANCHES',
    SET_BUILD_INCREMENT = 'SET_BUILD_INCREMENT',
    SET_KEY = 'SET_KEY',
    SET_VERSION = 'SET_VERSION',
    SET_MAX_AMOUNT = 'SET_MAX_AMOUNT',
    SET_POINTS = 'SET_POINTS',
    SET_STRIKE = 'SET_STRIKE',
    UNMUTE = 'UNMUTE',
    MUTE = 'MUTE',
    TOGGLE_MUTE = 'TOGGLE_MUTE',
    SAVE_GAME = 'SAVE_GAME',
    CLEANUP_QUEUE = 'CLEANUP_QUEUE',
    RECEIVE_ACHIEVEMENTS = 'RECEIVE_ACHIEVEMENTS',
    ACHIEVE = 'ACHIEVE',
    CHECK_ACHIEVEMENTS = 'CHECK_ACHIEVEMENTS',
    RECEIVE_UPGRADES = 'RECEIVE_UPGRADES'
