<template>
  <div class="hello">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3>{{ 'Welcome'|translate }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  },
  data() {
    return {}
  },
  sockets: {},
  methods: {},
  beforeCreate() {
  },
  beforeDestroy() {
  },
  unmounted() {
  }
}
</script>
