import store from '../store'
import Vue from 'vue'
import * as types from '../store/mutation-types'
import { EventBus } from '../api/eventbus'
import AuthService from '../services/auth.service'
import StatsService from '../services/stats.service'

let wasConnectedBefore = false,
    disconnectMessage  = '',
    counter            = 0,
    collectStats       = () => {}

export default {
    connect() {
        store.commit(types.INITIALIZE, true)
        this.$socket.client.emit('getVersion', '', version => {
            console.info('Connected to raitsch server version:', version)
        })
        if (wasConnectedBefore) {
            if (disconnectMessage !== null) {
                EventBus.$emit('clearMessage', disconnectMessage)
                disconnectMessage = null
            }
            EventBus.$emit('addMessage', {
                type:        'info',
                text:        Vue.i18n.translate('CONNECTION_FOUND'),
                headline:    Vue.i18n.translate('CONNECTION_FOUND_HEADLINE'),
                dismissible: true,
                check:       false,
                timeout:     5000
            })
        }
        wasConnectedBefore = true
    },
    gameInit(data) {
        const auth = new AuthService()
        auth.getUser()
        store.commit({
            type: types.SET_KEY,
            key:  data.key
        })
        store.commit({
            type:    types.SET_VERSION,
            version: data.serverVersion
        })
        Vue.i18n.set(store.state.game.language)
    },
    disconnect() {
        if (wasConnectedBefore) {
            store.commit(types.INITIALIZE, false)
            EventBus.$emit('addMessage', {
                type:        'danger',
                text:        Vue.i18n.translate('CONNECTION_LOST'),
                headline:    Vue.i18n.translate('CONNECTION_LOST_HEADLINE'),
                dismissible: true,
                check:       false,
                timeout:     false,
                cb:          msg => {
                    disconnectMessage = msg
                }
            })
        }
    }
}
