var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"fixed-top"},[_c('div',{staticClass:"nav-wrapper"},[_c('div',{staticClass:"container"},[_c('b-navbar',{attrs:{"toggleable":"md"}},[_c('b-nav-toggle',{attrs:{"target":"nav_collapse"}}),_c('div',{staticClass:"flex-row"},[_c('b-navbar-brand',{attrs:{"id":"raitsch"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('span',{staticClass:"nav-bar-nav"},[_c('a',{staticClass:"inner-nav-link",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_vm._v("Raitsch")])])]}}])})],1)],1),_c('b-collapse',{attrs:{"is-nav":"","id":"nav_collapse"}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('router-link',{attrs:{"to":"/about","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'router-link-active':isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm._f("translate")('About')))])])]}}])}),(_vm.user.isLoggedIn)?_c('router-link',{attrs:{"to":"/games","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'router-link-active':isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm._f("translate")('Games')))])])]}}],null,false,3749836915)}):_vm._e(),(!_vm.user.isLoggedIn)?_c('router-link',{attrs:{"to":"/signup","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'router-link-active':isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm._f("translate")('Signup')))])])]}}],null,false,1719777048)}):_vm._e(),(!_vm.user.isLoggedIn)?_c('router-link',{attrs:{"to":"/login","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'router-link-active':isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm._f("translate")('Login')))])])]}}],null,false,3853332461)}):_vm._e(),(_vm.user.isLoggedIn)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(_vm._s(_vm._f("translate")('Logout')))])]):_vm._e()],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }