import * as types from '../store/mutation-types'
import store from '../store'
import axios from 'axios'

/* eslint-disable */
class StatsService {
    getStats() {
        return axios({
            method: 'get',
            url:    `${store.state.game.apiURL}/api/stats`
        })
    }

    postStats(points) {
        let dataToSend  = {},
            user        = store.state.game.user,
            retries     = 0,
            refreshID   = false
        if (user.isLoggedIn) {
            // if (typeof user.provider === 'undefined') {
            //     //the user was not logged in before!
            //     dataToSend.oldID = game.userID
            // }
            // store.state.game.userID = user._id
        } else if (user._id === null || user._id === '') {
            user = {}
            user._id    = 'Anonymous'
            user.name   = 'Anonymous'
            // store.state.game.userID = user._id
        }

        dataToSend.points = points
        if (store.state.game.statsID === null || store.state.game.statsID === 0) {
            store.commit({
                type: types.SET_STATS_ID,
                id:   'xxx'
            })
            refreshID = true
        } else {
            dataToSend._id = store.state.game.statsID
        }
        dataToSend.name      = user.name
        dataToSend.timestamp = Date.now()

        return axios.put(`${store.state.game.apiURL}/api/stats/${store.state.game.statsID}`, dataToSend)
            .then(response => {
                if (refreshID === true) {
                    if (typeof response._id !== 'undefined') {
                        store.commit({
                            type: types.SET_STATS_ID,
                            id:   response._id
                        })
                    } else if (typeof response.data._id !== 'undefined') {
                        store.commit({
                            type: types.SET_STATS_ID,
                            id:   response.data._id
                        })
                    }
                }
            })
            .catch(response => {
                // when the stats entry is removed from the database, this could happen.
                if (response.status === 404 && ++retries < 10) {
                    // so remove the old statsID and refresh
                    delete dataToSend._id
                    refreshID = true
                    axios.put(`${store.state.game.apiURL}/api/stats/${store.state.game.statsID}`, dataToSend)
                        .then(response => {
                            if (refreshID === true) {
                                if (typeof response._id !== 'undefined') {
                                    store.commit({
                                        type: types.SET_STATS_ID,
                                        id:   response._id
                                    })
                                } else if (typeof response.data._id !== 'undefined') {
                                    store.commit({
                                        type: types.SET_STATS_ID,
                                        id:   response.data._id
                                    })
                                }
                            }
                            retries = 0
                        })
                }
            })
    }
}

export default new StatsService()
