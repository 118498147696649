import store from '../store'
import AuthService from '../services/auth.service'
import Sound from '../classes/sound'


export default VueInstance => {
    const auth = new AuthService(),
        sound = new Sound()
    VueInstance.prototype.$getUser = () => auth.getUser()
    VueInstance.prototype.$getAPIURL = () => store.state.game.apiURL
    VueInstance.prototype.$playSound = id => sound.play(id)
}
