/*eslint-disable */
export default {
    'Welcome': 'Welcome to Raitsch!',
    'No games available at the moment. Create one!': 'No games available at the moment. Create one!',
    'Delete': 'Delete',
    'Leave': 'Leave',
    'Start': 'Start',
    'Ready': 'Ready',
    'Scoreboard': 'Scoreboard',
    'Player count': 'Players',
    'Cards/Players': 'Cards/Players',
    'Your turn!': 'Your turn!',
    'Make your prediction': 'Make your prediction',
    'Yellow': 'Yellow',
    'Red': 'Red',
    'Blue': 'Blue',
    'Violet': 'Violet',
    'Green': 'Green',
    'Orange': 'Orange',
    'Submit': 'Submit',
    'Invalid!': 'Invalid!',
    'What value should your Joker have?': 'What value should your Joker have?',
    'Prediction': 'Prediction',
    'Ticks': 'Ticks',
    'Game': 'Game',
    'Games': 'Games',
    'Signup': 'Signup',
    'Login': 'Login',
    'Logout': 'Logout',
    'Status': 'Status',
    'open': 'open',
    'running': 'running',
    'finished': 'finished',
    'Enter': 'Enter',
    'created by': 'created by',
    'You are in this game': 'You are in this game',
    'Please log in': 'Please log in',
    'Available games': 'Available games',
    'Create new game': 'Create new game',
    'Create': 'Create',
    'Enter name': 'Enter name',
    'BUTTON_TEXT_EN': 'EN',
    'BUTTON_TEXT_DE': 'DE',
    'Email': 'Email',
    'Name': 'Name',
    'Password': 'Password',
    'Round finished.': 'Round finished.',
    'Game finished.': 'Game finished.',
    'New round starts.': 'New round starts.',
    'The winner is {player}': 'The winner is {player}',
    'Game starting - have fun!': 'Game starting - have fun!',
    'Prediction by {player}: {prediction}': 'Prediction by {player}: {prediction}',
    'Player {player} has entered the game.': 'Player {player} has entered the game.',
    'Player {player} has left the game.': 'Player {player} has left the game.',
    '{place}: {player} with {points} points': '{place}: {player} with {points} points',
    'Hello, {username}': 'Hello, {username}',
    'Public': 'Public',
    'private': 'private',
    'About': 'About',
    'Game is full': 'Game is full',
    'Not connected...': 'Not connected...',
    'MSG_INCORRECT_PASSWORD': 'Wrong password',
    'MSG_EMAIL_NOT_REGISTERED': 'Email not registered',
    'CONNECTION_FOUND': 'You can continue earning money.',
    'CONNECTION_FOUND_HEADLINE': 'Connection re-established.',
    'CONNECTION_LOST': 'The game cannot continue like this. Please wait.',
    'CONNECTION_LOST_HEADLINE': 'Connection to sever lost!',
    'WAITING_FOR_SERVER': 'Waiting for server connection...'
}
