// /* eslint no-sync: 0 */
/* eslint-disable */
import Vue from 'vue'
import store from '../store'
import axios from 'axios'
import User from '../classes/user'

export default class AuthService {
    constructor() {
        this.token = typeof Vue.cookie !== 'undefined' ? Vue.cookie.get('token') : null
        this.user = new User()
    }

    hasRole(role) {
        return this.user.role === role
    }

    getUser() {
        if (this.token && !this.user._id) {
            this.getUserData()
        }
        return this.user
    }

    getUserAsync() {
        if (this.user._id) {
            return new Promise((resolve, reject) => resolve(this.user))
        }
        return this.getUserData()
    }

    getUserData() {
        return axios({
            method:  'get',
            url:     `${store.state.game.apiURL}/api/users/me`,
            headers: {Authorization: `Bearer ${this.token}`}
        })
            .then(user => {
                this.user.name       = user.data.name
                this.user.email      = user.data.email
                this.user.role       = user.data.role
                this.user._id        = user.data._id
                this.user.isLoggedIn = true
                store.commit('USER_LOGIN', {user: this.user})
                return this.user
            })
            .catch(error => {
                console.error('error getting user data: ', error)
                if (error.response) {
                    console.error('response: ', error.response)
                }
            })
    }

    login(payload) {
        return axios.post(`${store.state.game.apiURL}/auth/local`, {
            username: payload.email,
            password: payload.password
        })
            .then(response => {
                Vue.cookie.set('token', response.data.token)
                this.token = response.data.token
                this.getUserData()
            })
    }

    signup(payload) {
        return axios.post(`${store.state.game.apiURL}/api/users`, {
            email:    payload.email,
            name:     payload.name,
            password: payload.password
        })
            .then(response => {
                Vue.cookie.set('token', response.data.token)
                this.token = response.data.token
                this.getUserData()
            })
    }

    changePassword(payload) {
        return axios.put(`${store.state.game.apiURL}/api/users/${store.state.game.user._id}/password`, payload)
    }

    failed(errors) {
        console.log('failed ', errors)
    }

    logout() {
        return new Promise((resolve, reject) => {
            this.user = new User()
            this.token = null
            if (Vue.cookie.get('token') === null) {
                reject(this.user)
            } else {
                Vue.cookie.delete('token')
                resolve(this.user)
            }
        })
    }
}
