<template>
  <div id="app">
    <page-header></page-header>
    <router-view  v-if="initialized"/>
    <div class="container" v-else>
      <b-alert variant="warning" show>
        <i class="fa fa-refresh fa-spin fa-2x"></i> <span class="h3">{{ $t('WAITING_FOR_SERVER') }}</span>
      </b-alert>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<style lang="scss">
.row {
  margin-bottom: 15px;

  ul {
    padding: 0;
  }
}

#chat {
  ul.chat {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  ul {
    height: 200px;
    overflow-y: scroll;
    margin: 0;
  }

  li.game {
    background: #eeeeee;
    .author {
      display: none;
    }
  }

  .author {
    font-weight: bold;
    font-style: italic;
  }

  ul, li {
    list-style: none;
    padding: 2px 5px;
  }
}

#player-data {
  margin-top: 3em;
}

.games .game {
  list-style: none;
  float: none;
  overflow: hidden;
}

#table {
  width: 100%;
  border: 4px solid #ccc;
  border-radius: 75px;
  position: relative;
  margin-bottom: 1em;

  .row {
    min-height: 8em;
    overflow: hidden;
    margin-bottom: 0;
  }
  .col-md-3 {
    text-align: center;
    padding: 0.5em;
  }
  .table-cards {
    padding-left: 10em;
  }
  .table-cards li {
    float: left;
    margin-left: -6em;
  }
  .first {
    text-align: left;
  }
  .last {
    text-align: right;
  }
}

@keyframes pulse {
  50% {
    border-color: red;
  }
}

@-webkit-keyframes pulse {
  50% {
    border-color: red;
  }
}

.player .playingCards .card {
  height: 6em;
  width: 4.5em;

  .card-color {
    padding: 1% 15px;
  }
  .value.value-middle {
    margin-top: 75%;
    font-size: 2em;
  }
}
</style>
<script>
import pageFooter from './components/Footer.vue'
import pageHeader from './components/Header.vue'
import { mapGetters } from 'vuex'

export default {
  name:       'app',
  components: { pageFooter, pageHeader },
  computed:   mapGetters({
    initialized: 'initialization'
  })
};
</script>
