<template>
  <div class="container-fluid">
    <div class="row meta mb-1 ml-1" v-if="gameData.game !== null && gameData.playerID">
      <span class="btn-group">
        <b-button v-b-modal.scoreboardmodal v-if="gameData.gameHasStarted">{{ 'Scoreboard'|translate }}</b-button>
        <button type="button" class="btn btn-success" :class="{'btn-primary' : gameData.hasJoined}"
                @click.prevent="toggleReady()">{{ 'Ready'|translate }}</button>
        <a class="btn btn-primary" :class="{'btn-danger' : gameData.hasJoined}" @click.prevent="leaveGame()">{{
            'Leave'|translate
          }}</a>
        <button type="button" class="btn btn-primary"
                :class="{'disabled' : gameIsStarted || !gameData.hasJoined}"
                @click.prevent="start()">{{ 'Start'|translate }}</button>
      </span>
    </div>
    <div class="row meta ml-1" v-if="gameIsFull">
      <div class="alert alert-danger">{{ 'Game is full'|translate }}</div>
    </div>
    <div class="row meta mb-1 ml-1" v-if="gameData.game !== null && !gameData.gameHasStarted">
      <span class="game-name">
        {{ 'Game'|translate }}: <span class="game-name">{{gameData.game._id}}</span> |
      </span>
      <span class="player-count">
        {{ 'Player count'|translate }}: <span class="badge badge-info players">{{ gameData.players.length }}</span>
      </span>
    </div>
    <b-modal id="scoreboardmodal" title="Scoreboard" :ok-only="true" size="xl">
      <table class="table table-striped table-bordered score-board"
             v-if="true||gameData.showScoreboard">
        <tr>
          <td>{{ 'Cards/Players'|translate }}</td>
          <td v-for="player in gameData.players" :key="'sb-' + player.id">{{ player.name }}</td>
        </tr>
        <tr v-for="(row, index) in gameData.rowNames">
          <td>{{ row }}</td>
          <td v-for="(player, pid) in gameData.players" style="padding:0;margin:0;">
            <table class="table table-bordered" style="margin:0;border:0 none;height: 40px;">
              <tr>
                <td class="points-in-round" rowspan="2" style="border: 0 none;">{{ getRoundPoints(index, pid) }}</td>
                <td class="prediction" style="border-top: 0 none;">{{ getPrediction(index, pid) }}</td>
                <td class="tricks" style="border-top: 0 none;border-right: 0 none;">{{ getTricks(index, pid) }}</td>
              </tr>
              <tr>
                <td class="prediction-points" style="border-bottom: 0 none;">{{ getPredictionPoints(index, pid) }}</td>
                <td class="action-card-points" style="border-bottom: 0 none;border-right: 0 none;">
                  {{ getActionCardPoints(index, pid) }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tfoot>
        <tr>
          <td>Total</td>
          <td v-for="(player, index) in gameData.players" :key="'total-' + player.id">{{ getTotal(index) }}</td>
        </tr>
        </tfoot>
      </table>
    </b-modal>
    <div class="container-fluid" v-if="gameData.hasJoined">
      <div id="table">
        <div class="row">
          <div class="col-md-3 first">
            <player :p="gameData.players" :idx="0" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3">
            <player :p="gameData.players" :idx="1" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3">
            <player :p="gameData.players" :idx="2" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3 last">
            <player :p="gameData.players" :idx="3" :turn="gameData.turn"></player>
          </div>
        </div>
        <div class="row playingCards">
          <div class="col-md-2">
            <ul class="trump-card">
              <li class="card" v-if="gameData.openCard !== null">
                <div class="value value-top">{{ gameData.openCard.value }}</div>
                <div class="card-color" :class="gameData.openCard.color">
                  <div class="value value-middle">{{ gameData.openCard.value }}</div>
                </div>
              </li>
            </ul>
            <ul class="last-trick mini-cards cards" @click.prevent="stackHover()" :class="{'stack':stack, 'unstacked': !stack}">
                <card v-for="(card, idx) in gameData.lastTable" :key="idx"
                      :canplay="() => false"
                      :playcard="()=>false"
                      :data="card.card"
                      :player="card.player"
                      :forceopen="true"
                      :marked="false"></card>
            </ul>
          </div>
          <div class="col-md-7 first">
            <ul class="table-cards" style="min-height: 8em;">
              <li class="card" v-for="tableCard in gameData.table" :key="tableCard.id">
                <div class="value value-top">{{ tableCard.value }}</div>
                <div class="card-color" :class="tableCard.color">
                  <div class="value value-middle">{{ tableCard.value }}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-3 last">
            <player :p="gameData.players" :idx="4" :turn="gameData.turn"></player>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 first">
            <player :p="gameData.players" :idx="8" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3">
            <player :p="gameData.players" :idx="7" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3">
            <player :p="gameData.players" :idx="6" :turn="gameData.turn"></player>
          </div>
          <div class="col-md-3 last">
            <player :p="gameData.players" :idx="5" :turn="gameData.turn"></player>
          </div>
        </div>
      </div>

      <div id="player-data">
        <div class="row align-items-start">
          <div class="col-lg-9">
            <div class="playingCards">
              <ul class="cards hand">
                <card v-for="card in gameData.cards" :key="card.id" :data="card"
                      :canplay="canPlayCard"
                      :playcard="playCard"
                      :forceopen="false"
                      :marked="isMarked(card)"></card>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 prediction" v-if="gameData.showPredictionInput">
              <label for="prediction">{{ 'Make your prediction'|translate }}</label>
              <span class="badge badge-info ml-1" v-if="invalidPrediction">{{ 'Invalid!'|translate }}</span>
              <div class="input-group" style="width: 200px">
                <input id="prediction" type="number" min="0" max="10" class="form-control"
                       v-model="gameData.prediction"/>
                <span class="input-group-append">
                    <button type="submit" class="btn btn-primary" @click.prevent="setPrediction()">{{
                        'Submit'|translate
                      }}</button>
                  </span>
              </div>
            </div>
          <div class="col joker" v-if="gameData.showJokerInput">
              <div class="form-group" style="width: 280px">
                <label for="joker">{{ 'What value should your Joker have?'|translate }}</label>
                <div class="rowx">
                  <div class="col-lg-6x mb-3">
                    <select class="form-control" v-model="gameData.joker.color">
                      <option value="yellow">{{ 'Yellow'|translate }}</option>
                      <option value="red">{{ 'Red'|translate }}</option>
                      <option value="blue">{{ 'Blue'|translate }}</option>
                      <option value="violet">{{ 'Violet'|translate }}</option>
                      <option value="green">{{ 'Green'|translate }}</option>
                      <option value="orange">{{ 'Orange'|translate }}</option>
                    </select>
                  </div>
                  <div class="col-lg-6x mb-3">
                    <input id="joker" type="number" size="3" min="0" max="999" class="form-control"
                           v-model="gameData.joker.value"/>
                  </div>
                </div>
                <input type="hidden" v-bind:value="gameData.jokerID"/>
                <button type="submit" class="btn btn-primary" @click.prevent="playCard(gameData.joker)">
                  {{ 'Submit'|translate }}
                </button>
              </div>
            </div>
        </div>
      </div>
      <div class="alert alert-info"
           v-if="(gameData.turn !== null && gameData.turn === gameData.playerID)">
        {{ 'Your turn!'|translate }}
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div id="chat">
            <ul class="chat" id="message-list" v-chat-scroll>
              <li class="chat-entry" v-for="message in gameData.messages" :class="message.type">
                <span class="author mr-1">{{ message.user }}</span>
                <span class="text">{{ message.text }}</span>
              </li>
            </ul>
            <form class="chat-form" v-on:submit.prevent>
              <div class="input-group">
                <input class="form-control" type="text" v-model="gameData.chatMessage"/>
                <span class="input-group-append">
                  <button class="btn btn-dark" @click.prevent="addChatMessage()">{{ 'Submit'|translate }}</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Player from './Player'
import AuthService from '../services/auth.service'
import Card from '@/components/Card.vue'

const auth = new AuthService()

export default {
  name: 'Game',
  data() {
    const gameData = this.resetGameData()
    return {
      games: [],
      locked: false,
      ok: false,
      newGame: '',
      stack: true,
      gameIsStarted: false,
      invalidPrediction: false,
      markedCard: null,
      timer: null,
      gameIsFull: false,
      gameData: gameData
    }
  },
  computed: mapGetters({
    user: 'user'
  }),
  sockets: {
    gameFull: function (data) {
      console.log('game is full: ', data)
      this.gameIsFull = true
    },
    newPrediction: function (data) {
      this.gameData.predictions.push(data)
      const player = this.gameData.players.find(p => p.id === data.player)
      if (player !== undefined) {
        player.prediction = data.prediction
        this.addMessage('game', this.$t('Prediction by {player}: {prediction}', {
          player: data.playerName,
          prediction: data.prediction
        }))
      }
    },
    newMessage: function (msg) {
      this.$playSound('s6')
      this.gameData.messages.push(msg)
    },
    newTrump: function (card) {
      console.debug('new trump!', card)
      this.gameData.openCard = card
    },
    playedCard: function (card) {
      console.debug('played-card!', card)
      this.$playSound('s8')
      this.gameData.table.push(card)
      if (card.firstCard === true) {
        console.debug('first card is ', card)
        this.gameData.firstCard = card
      }
    },
    finishedRound: function (data) {
      console.debug('finished round. tricks: ', data)
      this.addMessage('game', this.$t('Round finished.'), '')
    },
    calculatedPoints: function (data) {
      console.debug('calculatedPoints: ', data)
      this.gameData.scoreBoardData.push(data)
    },
    getPrediction: function (data) {
      console.debug('getPrediction()', data)
      if (data !== undefined) {
        if (typeof data.total !== 'undefined') {
          console.debug('getPrediction data: ', data)
          this.invalidPrediction = true
        }
        if (data.ID === this.gameData.playerID) {
          this.gameData.showPredictionInput = true
          this.initTimer()
        }
      }
    },
    loginFirst: function () {
      this.showMessage('Bitte einloggen!')
    },
    playersTurn: function (data) {
      console.debug('players turn: ', data)
      this.gameData.turn = data.turn
      if (data.turn === this.gameData.playerID) {
        this.$playSound('s4')
      }
    },
    setTurn: function (data) {
      console.debug('set-turn: ', data)
      this.gameData.turn = data.turn
      if (data.turn === this.gameData.playerID) {
        if (this.markedCard !== null) {
          if (this.canPlayCard(this.markedCard)) {
            this.playCard(this.markedCard)
          }
          this.markedCard = null
        } else {
          this.$playSound('s4')
          this.initTimer()
        }
      }
    },
    turnWon: function (data) {
      this.locked = true
      console.debug('turn won by: ', data)
      this.clearTimer()
      this.gameData.lastTable = data.lastTable
      this.addMessage('game', this.$t('The winner is {player}', {player: data.winnerName}), '')
      this.gameData.players = data.players
      this.gameData.firstCard = null
      setTimeout(() => {
        this.gameData.table = []
        this.locked = false
      }, 2500)
    },
    roundInit: function (data) {
      if (data.playerID !== this.gameData.playerID) {
        return
      }
      // console.debug('round-init ', data, 'my id:', this.gameData.playerID)
      this.addMessage('game', this.$t('New round starts.'), '')
      this.gameData.firstCard = null
      this.gameData.cards = data.cards.sort(function (a, b) {
        var aColor = (a.color === null) ? 'z' : a.color,
            bColor = (b.color === null) ? 'z' : b.color

        if (aColor > bColor) {
          return 1
        }
        if (aColor < bColor) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      })
      if (data.isLastRound === true) {
        this.gameData.cards[0].open = false
      }
      //this.gameData.playerID = data.playerID
      this.gameData.playerCount = data.players
      this.gameData.table = []
      this.gameData.players.forEach(p => {
        p.tricks = 0
        p.prediction = 0
      })
    },
    playerReady: function (data) {
      console.debug('player is ready: ', data)
      this.gameData.players = data
      this.addMessage('game', this.$t('Player {player} has entered the game.', {player: this.gameData.players[this.gameData.players.length - 1].name}))
    },
    gameFinished: function (data) {
      console.debug('finished game: ', data)
      this.$playSound('s2')
      this.gameData.players = data.players
      this.gameData.results = data.placement
      // $('#myModal').modal('show')
      this.$bvModal.show('scoreboardmodal')
      this.addMessage('game', this.$t('Game finished.'), '')
      this.gameData.results.forEach(res => {
        this.addMessage('game', this.$t('{place}: {player} with {points} points', {place: res.place, player: res.name, points: res.points}), '')
      })
    },
    playerReadyAck: function (data) {
      console.debug('player ready ack: ', data)
      if (data.hasOwnProperty('ok') && this.gameData.playerID === data.id) {
        this.gameData.hasJoined = true
      } else if (data.hasOwnProperty('rejoin') && data.rejoin === true) {
        console.debug('rejoin!', this.gameData)
      }
    },
    playerLeft: function (data) {
      console.debug('player left: ',data)
      if (data.hasOwnProperty('id') && data.id === this.gameData.playerID) {
        this.gameData.hasJoined = false
      }
      if (data.hasOwnProperty('players')) {
        this.gameData.players = data.players
      }
      if (data.hasOwnProperty('left')) {
        this.addMessage('game', this.$t('Player {player} has left the game.', {player: data.left}))
      }
    },
    hiddenCardDealt: function (data) {
      for (let i = 0; i < this.gameData.players.length; i++) {
        if (data.player !== this.gameData.playerID && this.gameData.players[i].id === data.player) {
          this.gameData.players[i].hiddenCard = data.card[0]
          break
        }
      }
    },
    gameStart: function (data) {
      console.debug('on gameStart - data: ', data)
      this.addMessage('game', this.$t('Game starting - have fun!'), '')
      this.gameData.gameHasStarted = true
    },
    initDeck: function (data) {
      console.debug('init deck!: ', data)
      this.$playSound('s3')
      this.gameData.deck = data.deck
      this.gameData.openCard = data.openCard
      this.gameData.predictions = []
    },
    reInitDeck: function (data) {
      console.debug('re-init deck!: ', data)
      this.$playSound('s3')
      this.gameData.deck = data.deck
      this.gameData.openCard = data.openCard
      this.gameData.predictions = []
      this.gameData.firstCard = null
      this.gameData.gameHasStarted = true
      this.gameData.cards = data.cards.sort(function (a, b) {
        var aColor = (a.color === null) ? 'z' : a.color,
            bColor = (b.color === null) ? 'z' : b.color

        if (aColor > bColor) {
          return 1
        }
        if (aColor < bColor) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      })
      if (data.isLastRound === true) {
        this.gameData.cards[0].open = false
      }
      //this.gameData.playerID = data.playerID
      this.gameData.playerCount = data.players
      this.gameData.table = []
    }
  },
  mounted() {
    this.gameData = this.resetGameData()
    // this is factory.getGame
    this.axios.get(`${this.$store.state.game.apiURL}/api/games/${this.$route.params.id}`).then(res => {
      this.ok = true
      this.gameData.game = res.data
      if (this.user.isLoggedIn) {
        this.gameData.playerID = this.user._id
        this.gameData.user = this.user
      } else {
        auth.getUserAsync().then(res => {
          if (res !== undefined && typeof res._id !== 'undefined') {
            this.gameData.playerID = res._id
            this.gameData.user = res
          }
        })
      }
    }).catch(err => {
      console.error('ERR:', err)
    })
  },
  methods: {
    test() {
      this.$socket.client.emit('test', 'test123 test 456')
    },
    isMarked(card) {
      return this.markedCard !== null && card.id === this.markedCard.id
    },
    initTimer() {
      this.timer = setTimeout(() => {
        this.$playSound('s7')
      }, 15000)
    },
    clearTimer() {
      if (this.timer !== null) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    stackHover(data) {
      this.stack = !this.stack
    },
    resetGameData() {
      return {
        user: {},
        showScoreboard: false,
        hasJoined: false,
        gameHasStarted: false,
        deck: [],
        openCard: null,
        playerID: null,
        turn: null,
        table: [],
        lastTable: [],
        tricks: [],
        cards: [],
        predictions: [],
        prediction: 0,
        showPredictionInput: false,
        showJokerInput: false,
        players: [],
        playerCount: 0,
        scoreBoardData: [],
        rowNames: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
        message: '',
        messages: [],
        firstCard: null,
        chatMessage: null,
        game: null,
        user: null,
        joker: {}
      }
    },
    addMessage(type, text, user) {
      this.gameData.messages.push({type: type, text: text, user: user})
    },
    isGameStarted() {
      return this.gameData.gameHasStarted
    },
    getRoundPoints(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].points
      }
    },
    getPrediction(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].prediction
      }
    },
    getActionCardPoints(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].actionCardPoints
      }
    },
    getPredictionPoints(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].predictionPoints
      }
    },
    getTotalPoints(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].totalPoints
      }
    },
    getTricks(round, playerID) {
      //console.debug('getting points for round ', round, ' and player ', playerID)
      if (this.gameData.scoreBoardData.length > round) {
        return this.gameData.scoreBoardData[round][playerID].tricks
      }
    },
    getTotal(playerID) {
      //console.debug('getTotal for ', playerID, this.gameData.scoreBoardData.length)
      if (this.gameData.scoreBoardData.length > 0) {
        return this.gameData.scoreBoardData[this.gameData.scoreBoardData.length - 1][playerID].totalPoints
      }
    },
    setPrediction() {
      console.debug('setting prediction to ', this.gameData.prediction)
      this.clearTimer()
      this.$socket.client.emit('setPrediction', this.gameData.prediction)
      this.gameData.showPredictionInput = false
      this.invalidPrediction = false
    },
    canPlayCard(card) {
      if (this.locked) {
        return false
      }
      if (this.gameData.turn === this.gameData.playerID && !this.gameData.showPredictionInput) {
        if (this.gameData.firstCard === null) {
          return true
        }
        if (this.gameData.firstCard.color === null || card.color === this.gameData.firstCard.color) {
          return true
        }
        var handCardsWithSameColor = 0
        const max = this.gameData.cards.length
        for (let i = 0; i < max; i++) {
          if (this.gameData.cards[i].color === this.gameData.firstCard.color) {
            ++handCardsWithSameColor
          }
          if (card.id !== this.gameData.cards[i].id) {
            if (this.gameData.cards[i].color === this.gameData.firstCard.color) {
              return false
            }
          }
        }
        if (handCardsWithSameColor === 0) {
          return true
        }
      }

      return false
    },
    playCard(card) {
      console.debug('playing card ', card.value, this.timer)
      this.clearTimer()
      if (this.canPlayCard(card)) {
        if (card.type === 'action' && card.value === 'J') {
          this.gameData.showJokerInput = true
          this.gameData.joker.id = card.id
          return
        }

        const max = this.gameData.cards.length
        for (let i = 0; i < max; i++) {
          if (typeof this.gameData.cards[i] !== 'undefined' && card.id === this.gameData.cards[i].id) {
            this.gameData.cards.splice(i, 1)
          }
        }
        this.$socket.client.emit('playCard', card)
        this.gameData.showJokerInput = false
      } else if (this.gameData.turn !== this.gameData.playerID) {
        if (this.markedCard !== null && this.markedCard.id === card.id) {
          this.markedCard = null
        } else {
          this.markedCard = card
        }
      }
    },
    addChatMessage() {
      // console.debug('adding msg', this.gameData.chatMessage)
      // console.debug('user: ', this.gameData.user.name, this.gameData.user)
      if (this.gameData.chatMessage !== null && this.gameData.chatMessage !== '') {
        this.$socket.client.emit('chatMessage', {
          type: 'chat',
          userID: this.gameData.userID,
          user: this.gameData.user.name,
          text: this.gameData.chatMessage
        })
        this.gameData.chatMessage = null
      }
    },
    addMessage(type, text, user) {
      this.gameData.messages.push({type: type, text: text, user: user})
    },
    showMessage(text) {
      this.gameData.message = text
    },
    toggleScoreboard() {
      this.gameData.showScoreboard = !this.gameData.showScoreboard
    },
    toggleReady() {
      console.debug('ready!', this.gameData.game)
      if (typeof this.gameData.game !== 'undefined' && this.gameData.game !== null) {
        const socketData = {player: this.user, game: this.gameData.game}
        if (this.gameData.hasJoined === false) {
          this.$socket.client.emit('ready', socketData)
        } else {
          this.$socket.client.emit('leave', socketData)
          console.debug('leaving.. players: ', this.gameData.players)
        }
      }
    },
    start() {
      this.$socket.client.emit('start', this.user)
    },
    leaveGame() {
      this.clearTimer()
      var socketData = {player: this.user, game: this.gameData.game}
      this.$socket.client.emit('leave', socketData)
      this.$router.push('/games')
      this.gameData.game = null
    }
  },
  beforeDestroy() {
    this.gameData = null
    this.clearTimer()
    this.$socket.client.removeAllListeners()
  },
  components: {
    Player,
    Card
  },
}
</script>
