import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import game from './modules/game'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const strict      = false && process.env.NODE_ENV !== 'production',
    vuexPersist = new VuexPersistence({
        strictMode:   strict,
        reducer:      state => ({game: state.game}),
        restoreState: (name, storage) => {
            if (typeof storage[name] === 'undefined') {
                return {}
            }
            return JSON.parse(storage[name])
        }
    })

export default new Vuex.Store({
    strict:    strict,
    actions,
    getters,
    modules:   {
        game
    },
    mutations: {
        RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION
    },
    plugins:   [vuexPersist.plugin]
})
